import { Component } from '@angular/core';
import { SharedModule } from "../../shared.module";

@Component({
  selector: 'app-new-product-photos-input',
  templateUrl: './new-product-photos-input.component.html',
  styleUrls: ['./new-product-photos-input.component.scss'],
  standalone: true,
  imports: [SharedModule],
})
export class NewProductPhotosInputComponent {
  photoLimit = 6;
  photosToSave: {isUpload:boolean, file: File, url?:string}[] = [];
  photosToDelete: number[] = [];
  photosToDisplay: { name: string; data: string }[] = [];
  remotePhotosToDisplay: { id: number; path: string; thumbnailPath: string; }[] = [];

  constructor() {}

  updatePhotosToDisplay($event: any) {
    if($event.target.files.length == 0)
      return;
    let currentPhotosNumber = this.photosToSave.length + this.remotePhotosToDisplay.length;
    if(currentPhotosNumber == this.photoLimit)
      return;
    let remainingPhotoNumber = this.photoLimit - currentPhotosNumber;
    if($event.target.files.length > remainingPhotoNumber) {
      for(let index = 0; index < remainingPhotoNumber; index++) {
        // this.photosToSave.push($event.target.files[index]);
        this.photosToSave.push({isUpload:false, file: $event.target.files[index]});
        this.photosToDisplay.push({
          name: $event.target.files[index].name,
          data: URL.createObjectURL($event.target.files[index]),
        });
      }
    } else {
      // this.photosToSave = [...$event.target.files];
      for(let index = 0; index < $event.target.files.length; index++) {
        this.photosToSave.push({isUpload:false, file: $event.target.files[index]});
        this.photosToDisplay.push({
          name: $event.target.files[index].name,
          data: URL.createObjectURL($event.target.files[index]),
        });
      }
    }

    // this.photosToDisplay = [];
    // for (const file of this.photosToSave) {
    //   this.photosToDisplay.push({
    //     name: file.name,
    //     data: URL.createObjectURL(file),
    //   });
    // }
  }

  removePhoto(name: string) {
    this.photosToSave = this.photosToSave.filter(
      (file) => file.file.name !== name,
    );
    this.photosToDisplay = this.photosToDisplay.filter(
      (photo) => photo.name !== name,
    );
  }

  removeRemotePhoto(id: number) {
    this.photosToDelete.push(id);
    this.remotePhotosToDisplay = this.remotePhotosToDisplay.filter(
      (photo) => photo.id !== id,
    );
  }

  removeAllPhotos() {
    this.photosToSave = [];
    this.photosToDisplay = [];
  }
}
