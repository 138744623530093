<app-loader *ngIf="isLoading"></app-loader>
<div class="user-profile">
  <form [formGroup]="formGroup" novalidate>
    <div class="row">
      <div class="col-md-6">
        <div class="form-field mb-4">
          <div
            class="d-flex gap-3 align-items-center mb-4 flex-column flex-wrap align-content-start w-50 float-left">
            <label htmlFor="upload1" class="required">
              {{ 'COMPONENTS.ITEMS.COVER_IMAGE' | translate }}
              <button
                type="button"
                class="btn-transparent ms-2 tooltip-btn"
                tooltipClass="tooltip-info-details"
                placement="right"
                [ngbTooltip]="logoTipContent">
                <i class="middle icon-info-circle-outline f-18"></i>
              </button>
            </label>
            <div class="wd-100">
              <img
                [src]="itemCoverImage"
                class="w-100 user-img"
                alt="user" />
            </div>
            <input
              [attr.disabled]="pageMode === 'view' ? true : null"
              [class.disabled]="pageMode === 'view'"
              type="file"
              id="upload1"
              hidden
              (change)="onPictureUpload($event)"
              accept="image/png, image/jpg, image/jpeg" />

            <label
              for="upload1"
              onKeyPress="onPictureUpload($event)"
              [class.disabled]="pageMode === 'view'"
              class="btn btn-outline btn-outline-primary btn-140">
              {{ 'COMPONENTS.ITEMS.UPLOAD_PHOTO' | translate }}
            </label>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-4 align-items-center d-flex justify-content-between flex-row-reverse">
          <div class="d-flex flex-row-reverse">
            @if (isEditMode() && isLoading === false) {
              <status
                [data]="baseModel"
                [element]="baseModel"
                [options]="constantList.STATUS_ARRAY"
                [column]="column"
                (updatedStatus)="updateStatus($event.status)"></status>
            }
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-field mb-4">
        <label htmlFor="productPictures">
          {{ 'Item Pictures' | translate }}
        </label>

        <app-new-product-photos-input></app-new-product-photos-input>
        <ng-container
          [ngTemplateOutlet]="reqErrRef"
          [ngTemplateOutletContext]="{
            control: formGroup.controls['images']
          }"></ng-container>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-field mb-4">
          <label htmlFor="name" class="required">
            {{ 'COMPONENTS.ITEMS.NAME' | translate }}
          </label>
          <input
            [class.disabled]="pageMode === 'view'"
            formControlName="name"
            name="name"
            id="name"
            appNoConsecutiveSpaces
            class="form-control"
            type="text"
            placeholder="{{ 'COMPONENTS.ITEMS.NAME' | translate }}" />
          <ng-container
            [ngTemplateOutlet]="reqErrRef"
            [ngTemplateOutletContext]="{
              control: formGroup.controls['name']
            }"></ng-container>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-field mb-4">
          <label htmlFor="description" class="required">
            {{ 'COMPONENTS.ITEMS.DESCRIPTION' | translate }}
          </label>
          <input
            [class.disabled]="pageMode === 'view'"
            id="description"
            appNoConsecutiveSpaces
            name="description"
            class="form-control"
            formControlName="description"
            type="description"
            placeholder="{{ 'COMPONENTS.ITEMS.DESCRIPTION' | translate }}" />
          <ng-container
            [ngTemplateOutlet]="reqErrRef"
            [ngTemplateOutletContext]="{
              control: formGroup.controls['description']
            }"></ng-container>
        </div>
      </div>
    </div>
    <div class="row">
      <div *ngIf="pageMode === 'edit'" class="col-md-6">
        <div class="form-field mb-4">
          <label htmlFor="id" class="required">
            {{ 'COMPONENTS.ITEMS.ID' | translate }}
          </label>
          <input
            id="id"
            [class.disabled]="
              pageMode === 'view' || (pageMode === 'edit' && hasSpecificRole === false)
            "
            name="id"
            class="form-control"
            formControlName="id"
            type="text"
            readonly
            onfocus="this.removeAttribute('readonly');"
            placeholder="" />
          <ng-container
            [ngTemplateOutlet]="reqErrRef"
            [ngTemplateOutletContext]="{ control: formGroup.controls['id'] }"></ng-container>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-field mb-4">
          <label htmlFor="categoryId" class="required">
            {{ 'Category' | translate }}
          </label>
          <ng-select
            [clearSearchOnAdd]="true"
            class="bg-white w-100 cus-select clear-disable"
            [searchable]="true"
            [clearable]="false"
            formControlName="categoryId"
            bindValue="id"
            [closeOnSelect]="true"
            [bindLabel]="getLanguageSpecificKey('name')"
            name="categoryId"
            placeholder="{{ 'Category' | translate }}">
            <ng-option *ngFor="let item of categoriesArray" [value]="item.id">
              {{ item.name }}
            </ng-option>
          </ng-select>
          <ng-container
            [ngTemplateOutlet]="reqErrRef"
            [ngTemplateOutletContext]="{
              control: formGroup.controls['categoryId']
            }"></ng-container>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-field mb-4">
          <label htmlFor="subCategoryId" class="required">
            {{ 'Subcategory' | translate }}
          </label>
          <ng-select
            [clearSearchOnAdd]="true"
            class="bg-white w-100 cus-select clear-disable"
            [searchable]="true"
            [clearable]="false"
            formControlName="subCategoryId"
            bindValue="id"
            [closeOnSelect]="true"
            [bindLabel]="getLanguageSpecificKey('name')"
            name="subCategoryId"
            placeholder="{{ 'Subcategory' | translate }}">
            <ng-option *ngFor="let item of subCategoriesArray" [value]="item.id">
              {{ item.name }}
            </ng-option>
          </ng-select>
          <ng-container
            [ngTemplateOutlet]="reqErrRef"
            [ngTemplateOutletContext]="{
              control: formGroup.controls['subCategoryId']
            }"></ng-container>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-field mb-4">
          <label htmlFor="storeId" class="required">
            {{ 'Store' | translate }}
          </label>
          <ng-select
            [clearSearchOnAdd]="true"
            class="bg-white w-100 cus-select clear-disable"
            [searchable]="true"
            [clearable]="false"
            formControlName="storeId"
            bindValue="id"
            [closeOnSelect]="true"
            [bindLabel]="getLanguageSpecificKey('name')"
            name="storeId"
            placeholder="{{ 'Store' | translate }}">
            <ng-option *ngFor="let item of storesArray" [value]="item.id">
              {{ item.name }}
            </ng-option>
          </ng-select>
          <ng-container
            [ngTemplateOutlet]="reqErrRef"
            [ngTemplateOutletContext]="{
              control: formGroup.controls['storeId']
            }"></ng-container>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-field mb-4">
          <label htmlFor="brandId" class="required">
            {{ 'Brand' | translate }}
          </label>
          <ng-select
            [clearSearchOnAdd]="true"
            class="bg-white w-100 cus-select clear-disable"
            [searchable]="true"
            [clearable]="false"
            formControlName="brandId"
            bindValue="id"
            [closeOnSelect]="true"
            [bindLabel]="getLanguageSpecificKey('name')"
            name="brandId"
            placeholder="{{ 'Brand' | translate }}">
            <ng-option *ngFor="let item of brandsArray" [value]="item.id">
              {{ item.name }}
            </ng-option>
          </ng-select>
          <ng-container
            [ngTemplateOutlet]="reqErrRef"
            [ngTemplateOutletContext]="{
              control: formGroup.controls['brandId']
            }"></ng-container>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-field mb-4">
          <label htmlFor="styleId" class="required">
            {{ 'Style' | translate }}
          </label>
          <ng-multiselect-dropdown
            class="bg-white w-100 cus-select clear-disable"
            [settings]="multiDropdownSettings"
            [(ngModel)]="formGroup.value['styleId']"
            [data]="styleTagArray"
            formControlName="styleId"
            bindValue="id"
            name="styleId"
            placeholder="{{ 'Select Style ' | translate }}">
          </ng-multiselect-dropdown>
          <ng-container
            [ngTemplateOutlet]="reqErrRef"
            [ngTemplateOutletContext]="{
              control: formGroup.controls['styleId']
            }"></ng-container>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-field mb-4">
          <label htmlFor="baseMaterialId" class="required">
            {{ 'Base Material' | translate }}
          </label>
          <ng-multiselect-dropdown
            class="bg-white w-100 cus-select clear-disable"
            [settings]="multiDropdownSettings"
            [(ngModel)]="formGroup.value['baseMaterialId']"
            [data]="materialTagArray"
            formControlName="baseMaterialId"
            bindValue="id"
            name="baseMaterialId"
            placeholder="{{ 'Select Base Material ' | translate }}">
          </ng-multiselect-dropdown>
          <ng-container
            [ngTemplateOutlet]="reqErrRef"
            [ngTemplateOutletContext]="{
              control: formGroup.controls['baseMaterialId']
            }"></ng-container>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-field mb-4">
          <label htmlFor="finishId" class="required">
            {{ 'Finish' | translate }}
          </label>
          <ng-multiselect-dropdown
            class="bg-white w-100 cus-select clear-disable"
            [settings]="multiDropdownSettings"
            [(ngModel)]="formGroup.value['finishId']"
            [data]="finishTagArray"
            formControlName="finishId"
            bindValue="id"
            name="finishId"
            placeholder="{{ 'Select Finish ' | translate }}">
          </ng-multiselect-dropdown>
          <ng-container
            [ngTemplateOutlet]="reqErrRef"
            [ngTemplateOutletContext]="{
              control: formGroup.controls['finishId']
            }"></ng-container>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-field mb-4">
          <label htmlFor="order" class="required">
            {{ 'Item Order' | translate }}
          </label>
          <input
            [class.disabled]="pageMode === 'view'"
            formControlName="order"
            name="order"
            id="order"
            class="form-control"
            type="text"
            onlyInteger
            min="0"
            max= "99"
            minlength="1"
            maxlength="2"
            placeholder="Order" />
          <ng-container
            [ngTemplateOutlet]="reqOrderErrRef"
            [ngTemplateOutletContext]="{
              control: formGroup.controls['order']
            }"></ng-container>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-field mb-4">
          <label htmlFor="sku" class="required">
            {{ 'Item SKU' | translate }}
            <button
                type="button"
                class="btn-transparent ms-2 tooltip-btn"
                tooltipClass="tooltip-info-details tooltip-lg"
                placement="right"
                [ngbTooltip]="skuTipContent">
                <i class="middle icon-info-circle-outline f-18"></i>
              </button>
          </label>
          <input
            [class.disabled]="pageMode === 'view'"
            formControlName="sku"
            name="sku"
            id="sku"
            appNoConsecutiveSpaces
            class="form-control"
            type="text"
            placeholder="{{ 'Item SKU' | translate }}" />
          <ng-container
            [ngTemplateOutlet]="reqErrRef"
            [ngTemplateOutletContext]="{
              control: formGroup.controls['sku']
            }"></ng-container>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-field mb-4">
          <label htmlFor="price" class="required">
            {{ 'Item Price' | translate }}
          </label>
          <input
            [class.disabled]="pageMode === 'view'"
            id="price"
            appNoConsecutiveSpaces
            name="price"
            class="form-control"
            formControlName="price"
            type="number"
            placeholder="{{ 'Item Price' | translate }}" />
          <ng-container
            [ngTemplateOutlet]="reqErrRef"
            [ngTemplateOutletContext]="{
              control: formGroup.controls['price']
            }"></ng-container>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-field mb-4">
          <label htmlFor="id">
            {{ 'Variations/Alternative Items' | translate }}
          </label>
          <ng-multiselect-dropdown
            class="bg-white w-100 cus-select clear-disable"
            [settings]="multiDropdownAlternativeItemsSettings"
            [data]="productsArray"
            [(ngModel)]="alternativeProductsArray"
            [ngModelOptions]="{standalone: true}"
            placeholder="{{ 'Select Items ' | translate }}">
          </ng-multiselect-dropdown>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-field mb-4">
          <label htmlFor="id">
            {{ 'Similar Items' | translate }}
          </label>
          <ng-multiselect-dropdown
            class="bg-white w-100 cus-select clear-disable"
            [settings]="multiDropdownSimilarItemsSettings"
            [data]="productsArray"
            [(ngModel)]="similarProductsArray"
            [ngModelOptions]="{standalone: true}"
            placeholder="{{ 'Select Items ' | translate }}">
          </ng-multiselect-dropdown>
        </div>
      </div>
    </div>

    <div class="loader-container mb-2" *ngIf="isSaving && totalFiles > 0">
      <div  class="upload-status">
        <p>{{ uploadStatus }}</p>
      </div>

      <div class="progress-bar-container">
        <div class="progress-bar" [style.width.%]="uploadProgress"></div>
      </div>
    </div>

    <div *ngIf="pageMode === 'edit'" class="d-flex justify-content-end gap-2">
      <div *ngIf="isSaving" class="spinner-container d-flex justify-content-center align-content-center align-items-center">
        <div class="spinner-border" role="status"></div>
      </div>
      <button
        (click)="setItem('reset')"
        [disabled]="isSaving"
        type="button"
        class="btn btn-outline btn-outline-primary btn-140">
        {{ 'GENERAL.BUTTONS.RESET' | translate }}
      </button>
      <button
        type="button"
        [disabled]="isSaving"
        appDebounceClick
        (debounceClick)="onSubmit('update')"
        class="btn btn-primary btn-140">
        {{ 'GENERAL.BUTTONS.UPDATE' | translate }}
      </button>
    </div>
    <div *ngIf="pageMode === 'add'" class="d-flex justify-content-end gap-2">
      <div *ngIf="isSaving" class="spinner-container d-flex justify-content-center align-content-center align-items-center">
        <div class="spinner-border" role="status"></div>
      </div>
      <button
        (click)="setItem('cancel')"
        [disabled]="isSaving"
        type="button"
        class="btn btn-outline btn-outline-primary btn-140">
        {{ 'GENERAL.BUTTONS.CANCEL' | translate }}
      </button>
      <button
        type="button"
        [disabled]="isSaving"
        appDebounceClick
        (debounceClick)="onSubmit('add')"
        class="btn btn-primary btn-140">
        {{ 'GENERAL.BUTTONS.SAVE' | translate }}
      </button>
    </div>
  </form>
</div>

<ng-template #reqErrRef let-control="control" let-name="name">
  @if ((control?.touched || control?.dirty) && control?.invalid) {
    @if (control?.hasError('required')) {
      <small class="text-danger">{{ 'COMMON.VALIDATION.REQUIRED' | translate }}</small>
    } @else if (control?.hasError('pattern')) {
      <small class="text-danger">{{ 'COMMON.VALIDATION.INVALID_FORMAT' | translate }}</small>
    } @else if (
      name === 'employeeId' && (control?.hasError('minlength') || control?.hasError('maxlength'))
    ) {
      <small class="text-danger">
        {{ 'COMMON.VALIDATION.USER_ID_VALIDATION' | translate }}
      </small>
    }
  }
</ng-template>
<ng-template #tipContent>
  @if (this.userType === 'app_user') {
    <ul class="mb-0 ps-3">
      <li class="tx-12">{{ 'COMPONENTS.USERS.LENGTH_SIX_DIGITS' | translate }}</li>
    </ul>
  } @else {
    <ul class="mb-0 ps-3">
      <li class="tx-12">{{ 'COMPONENTS.USERS.LENGTH_EIGHT_SIXTEEN_CHARACTERS' | translate }}</li>
      <li class="tx-12">{{ 'COMPONENTS.USERS.ONE_CAPITAL' | translate }}</li>
      <li class="tx-12">{{ 'COMPONENTS.USERS.ONE_LOWER' | translate }}</li>
      <li class="tx-12">{{ 'COMPONENTS.USERS.ONE_NUMBER' | translate }}</li>
    </ul>
  }
</ng-template>
<ng-template #reqOrderErrRef let-control="control" let-name="name">
  @if ((control?.touched || control?.dirty) && control?.invalid) {
    @if (control?.hasError('required')) {
      <small class="text-danger">{{ 'COMMON.VALIDATION.REQUIRED' | translate }}</small>
    } @else {
      <small class="text-danger">{{ 'COMMON.VALIDATION.INVALID_ORDER_VALUE' | translate }}</small>
    }
  }
</ng-template>
<ng-template #logoTipContent>
  <ul class="mb-0 ps-3">
    <li class="tx-12">Size: 0.3 MB</li>
    <li class="tx-12">Dimensions: 1200x900</li>
  </ul>
</ng-template>
<ng-template #skuTipContent>
  <ul class="mb-0 ps-3">
    <li class="tx-12">SKU must starts with two letters (uppercase or lowercase), followed by exactly eight digits</li>
    <li class="tx-12">e.g AA12345678</li>
  </ul>
</ng-template>
