<div class="homeowner-detail">

    <div class="d-flex justify-content-end align-items-center mb-3">

        <!-- Profile Image -->


        <div ngbDropdown>
            <button [disabled]="isSuperAdmin() === true" [class.disabled]="isSuperAdmin() === true"
          class="dropdown-toggle btn-dropdown btn-140 btn-outline btn-outline-primary ms-2 mb-0"
          [ngClass]="status[baseModel?.status]?.toLowerCase()" id="dropdownStatus" ngbDropdownToggle>
          @switch (baseModel.profileStatus) {
          @case ('active') {
          <i class="icon middle me-2 icon-tick-circle"></i>
          }
          @case ('inactive') {
          <i class="icon middle me-2 icon-close-circle"></i>
          }
          @default {
          <i class="icon middle me-2 icon-minus-cirlce"></i>
          }
          }
          {{ 'COMMON.TEXT.' + status[baseModel?.status]?.toUpperCase() | translate }}
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownStatus">
          <button ngbDropdownItem (click)="updateUserStatus('active', baseModel.id)"
            [disabled]="baseModel.status === 'active'">
            <i class="icon middle me-2 icon-tick-circle" [ngClass]="{'text-muted': baseModel.status === 'active'}"></i>
            {{ 'GENERAL.BUTTONS.ACTIVATE' | translate }}
          </button>
          <button ngbDropdownItem (click)="updateUserStatus('inactive', baseModel.id)"
            [disabled]="baseModel.status === 'inactive'">
            <i class="icon middle me-2 icon-close-circle"
              [ngClass]="{'text-muted': baseModel.status === 'inactive'}"></i>
            {{ 'GENERAL.BUTTONS.DEACTIVATE' | translate }}
          </button>
        </div>
        </div>

    </div>

    <!-- Section 1: General Info -->
    <div class="col-lg-12">
        <div class="card mb-4">
            <div class="card-body">
                <h3 class="mb-3">{{ 'COMPONENTS.USERS.GENERAL_INFO' | translate }}</h3>

                <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">First Name</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="text-muted mb-0">{{baseModel.firstName || 'NA' }}</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="mb-0">Last Name</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="text-muted mb-0">{{baseModel.lastName || 'NA' }}</p>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">Email Address</p>
                    </div>
                    <div class="col-sm-9">
                        <p class="text-muted mb-0">{{baseModel.email || 'NA' }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Section 2: Shipping Address -->
    <div class="col-lg-12"  *ngIf="baseModel.shippingAddress">
        <div class="card mb-4">
            <div class="card-body">
                <h3 class="mb-3">{{ 'COMPONENTS.USERS.SHIPPING_ADDRESS' | translate }}</h3>

                <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">First Name</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="text-muted mb-0">{{baseModel.shippingAddress.firstName || 'NA' }}</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="mb-0">Last Name</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="text-muted mb-0">{{baseModel.shippingAddress.lastName || 'NA' }}</p>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">Country</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="text-muted mb-0">{{baseModel.shippingAddress.country || 'NA' }}</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="mb-0">City</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="text-muted mb-0">{{baseModel.shippingAddress.city || 'NA' }}</p>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">State</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="text-muted mb-0">{{baseModel.shippingAddress.state || 'NA' }}</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="mb-0">Location URL</p>
                    </div>
                    <div class="col-sm-3">
                        <div class="attached-file" (click)="openURL(baseModel.shippingAddress.location)">
                            <img alt="" src="../../../../../../assets/images/link.svg" />
                            <div>
                              <div>Click to open</div>
                            </div>
                          </div>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">Address</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="text-muted mb-0">{{baseModel.shippingAddress.address || 'NA' }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Section 2: Billing Address -->
    <div class="col-lg-12" *ngIf="baseModel.billingAddress">
        <div class="card mb-4">
            <div class="card-body">
                <h3 class="mb-3">{{ 'COMPONENTS.USERS.BILLING_ADDRESS' | translate }}</h3>
                <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">First Name</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="text-muted mb-0">{{baseModel.billingAddress.firstName || 'NA' }}</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="mb-0">Last Name</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="text-muted mb-0">{{baseModel.billingAddress.lastName || 'NA' }}</p>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">Country</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="text-muted mb-0">{{baseModel.billingAddress.country || 'NA' }}</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="mb-0">City</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="text-muted mb-0">{{baseModel.billingAddress.city || 'NA' }}</p>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">State</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="text-muted mb-0">{{baseModel.billingAddress.state || 'NA' }}</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="mb-0">Location URL</p>
                    </div>
                    <div class="col-sm-3">
                        <div class="attached-file" (click)="openURL(baseModel.billingAddress.location)">
                            <img alt="" src="../../../../../../assets/images/link.svg" />
                            <div>
                              <div>Click to open</div>
                            </div>
                          </div>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">Address</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="text-muted mb-0">{{baseModel.billingAddress.address || 'NA' }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<app-confirm-dialog [confirmText]="dialogConfirmText" [closeText]="'GENERAL.BUTTONS.CLOSE' | translate"
    [message]="dialogMessage" extraIcon="icon-warning-2" iconClass="text-danger" [eventType]="confirmEventAction"
    (closeDialog)="processModalClose($event)"></app-confirm-dialog>
    