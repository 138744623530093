<div class="modal-ei modal-backdrop-ei" role="dialog" *ngIf="open" style="display:block;">
  <div class="modal-dialog-ei" role="document">
    <div class="modal-content-ei">
      <div class="modal-header-ei">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="discardWrapper($event)">
          Close
        </button>
      </div>
      <div class="modal-body1">
        <img class="image-view" [src]="config.imageUrl" alt="expanded"/>
      </div>
      
    </div>
  </div>
</div>