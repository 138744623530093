import { Injectable } from '@angular/core';
import {
  S3Client,
  PutObjectCommand,
  GetObjectCommand,
  ListObjectsV2Command,
  DeleteObjectCommand,
} from '@aws-sdk/client-s3';
import { from, map, Observable } from 'rxjs';
import { AWS_PUBLIC_BUCKET, AWS_REGION, AWS_S3_ACCESS_KEY, AWS_S3_KEY_SECRET } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class S3Service {
  private awsS3: S3Client;
  private bucketName =  AWS_PUBLIC_BUCKET;

  private baseUrl = 'https://sid-stg-public.s3.amazonaws.com/';

  constructor() {
    this.awsS3 = new S3Client({
      credentials: {
        accessKeyId: AWS_S3_ACCESS_KEY,
        secretAccessKey: AWS_S3_KEY_SECRET,
      },
      region: AWS_REGION,
    });
  }

  /**
   * the following method uploads files to S3 to the respective bucket
   * @param file
   * @param bucket
   * @param customFileName
   * @param folder
   * @returns
   */

  async uploadFileV2(
    file: any,
    bucket: string,
    customFileName?: string,
    folder?: string,
    fileMimetype?: string,
  ): Promise<string> {
    const fileName =
      customFileName ??
      file.name ??
      'image_' + Number(new Date()) + '.jpg';
    // in case the file name is missing we upload it as image_<current-timestamp> to make it unique
    return await this.s3Upload(
      file,
      bucket,
      folder ? folder + '/' + fileName : fileName,
      file?.mimetype ?? fileMimetype,
    );
  }

  async uploadBase64Image(
    base64Str: any,
    key: string,
    bucketName?: string,
  ): Promise<any> {
    const mimeType = base64Str.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];

    const byteCharacters = atob(base64Str.split(',')[1]);
    const byteNumbers = Array.from(byteCharacters).map(char => char.charCodeAt(0));
    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], { type: mimeType });

    try {
      // Upload to S3
      const uploadResponse = await this.s3Upload(
        blob,
        bucketName || this.bucketName,
        key,
        mimeType
      );

      // Return response with key and upload details
      return {
        key: key,
        response: uploadResponse
      };
    } catch (error) {
      console.error('Error uploading base64 image:', error);
      throw error; // Propagate the error to be handled by the caller
    }
  }


  private async s3Upload(
    file: any,
    bucket: string,
    name: string,
    mimetype: string,
    contentEncoding?: string,
  ): Promise<string> {
    const params: any = {
      Bucket: bucket,
      Key: name,
      Body: file,
      ContentType: mimetype,
      ContentDisposition: 'inline',
    };
    if (contentEncoding) {
      params.ContentEncoding = contentEncoding;
    }
    try {
      const command = new PutObjectCommand(params);
      await this.awsS3.send(command);
      //   return `${config.aws.cdnUrl}/${name}`;
      return `${name}`;
    } catch (e) {
      console.log('error => ', e);
      throw e;
    }
  }

  /**
   * Upload a file to S3
   * @param file - The file to upload
   * @param key - The key (path) under which to store the file in the bucket
   * @returns Observable with upload response
   */
  uploadFile(file: File, key: string, bucketName?: string): Observable<any> {
    const params = {
      Bucket: bucketName || this.bucketName,
      Key: key,
      Body: file,
      ContentType: file.type,
    };
    const command = new PutObjectCommand(params);
    return from(this.awsS3.send(command)).pipe(
      map(response => ({
        key: key,
        response: response
      }))
    );
  }

  /**
   * Download a file from S3
   * @param key - The key (path) of the file in the bucket
   * @returns Observable with the file Blob
   */
  downloadFile(key: string, bucketName?: string): Observable<Blob> {
    const params = {
        Bucket: bucketName || this.bucketName,
      Key: key,
    };
    const command = new GetObjectCommand(params);
    return new Observable((observer) => {
      this.awsS3.send(command).then(
        (data: any) => {
          const blob = new Blob([data.Body as any]);
          observer.next(blob);
          observer.complete();
        },
        (error:any) => {
          observer.error(error);
        }
      );
    });
  }

  /**
   * List files in S3 bucket
   * @param prefix - Optional prefix to filter listed objects
   * @returns Observable with list of file keys
   */
  listFiles(prefix: string = '', bucketName?: string): Observable<string[]> {
    const params = {
      Bucket: bucketName || this.bucketName,
      Prefix: prefix,
    };
    const command = new ListObjectsV2Command(params);
    return new Observable((observer) => {
      this.awsS3.send(command).then(
        (data: any) => {
          const keys = data.Contents?.map((item:any) => item.Key!) || [];
          observer.next(keys);
          observer.complete();
        },
        (error:any) => {
          observer.error(error);
        }
      );
    });
  }


    /**
   * Get the full URL for accessing a file directly via HTTP
   * @param key - The key (path) of the file in the bucket
   * @returns Full URL for accessing the file
   */
    getFileUrl(key: string): string {
        return `${this.baseUrl}${key}`;
      }

  /**
   * Delete a file from S3
   * @param key - The key (path) of the file to delete
   * @returns Observable with delete response
   */
  deleteFile(key: string, bucketName?: string): Observable<any> {
    const params = {
      Bucket: bucketName || this.bucketName,
      Key: key,
    };
    const command = new DeleteObjectCommand(params);
    return from(this.awsS3.send(command));
  }
}
