import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiResponseInterface } from '@src/app/core/interfaces/api-response.interface';

import { HttpService } from '@src/app/core/services/http.service';
import {
  USER_BY_ID_BASE_URL,
  USER_MANAGEMENT_IMPORT_EXCEL,
  USER_MANAGEMENT_DOWNLOAD_TEMPLATE,
  USER_MANAGEMENT_BULK_STATUS,
  USER_MANAGEMENT_STATUS_UPDATE,
  UPDATE_USER_PICTURE_BASE_URL,
  ADD_ADMIN_USER_BASE_URL,
  UPDATE_USER_PASSWORD_BASE_URL,
  DOWNLOAD_USER_DOCUMETS_BASE_URL,
  DELETE_USER_DOCUMETS_BASE_URL,
  UPDATE_USER_DOCUMENT_BASE_URL,
  UPLOAD_USER_DOCUMETS_BASE_URL,
  UPDATE_USER_OFFICIAL_DETAILS_BASE_URL,
  EDIT_ADMIN_USER_BY_ID_BASE_URL,
  UPDATE_USER_ADDRESS_BASE_URL,
  REGENERATE_PASSWORD_BY_ID_BASE_URL,
  PROFESSIONAL_BY_ID_BASE_URL,
  HOME_OWNER_BY_ID_BASE_URL,
} from '@src/app/features/user-management/core';

@Injectable({
  providedIn: 'root',
})
export class UserRepository {
  constructor(private httpService: HttpService) {}

  create(body: any): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity('POST', ADD_ADMIN_USER_BASE_URL, body);
  }
  update(id: string, body: any): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity('PATCH', EDIT_ADMIN_USER_BY_ID_BASE_URL.replace('{id}', id), body);
  }
  updateUserDetail(id: string, body: any): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity(
      'PUT',
      UPDATE_USER_OFFICIAL_DETAILS_BASE_URL.replace('{id}', id),
      body,
    );
  }
  updateUserDocumentFile(id: string, body: any): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity(
      'PUT',
      UPDATE_USER_DOCUMENT_BASE_URL.replace('{id}', id),
      body,
    );
  }
  updateUserDocument(id: string, body: any): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity(
      'PUT',
      UPLOAD_USER_DOCUMETS_BASE_URL.replace('{id}', id),
      body,
      this.httpService.multiPartFormDataHeaders,
    );
  }
  updatePassword(id: string, body: any): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity(
      'PATCH',
      UPDATE_USER_PASSWORD_BASE_URL.replace('{id}', id),
      body,
    );
  }
  regeneratePassword(id: string): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity('PATCH', REGENERATE_PASSWORD_BY_ID_BASE_URL.replace('{id}', id));
  }
  updateDetailByPayload(body: any): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity('PATCH', USER_MANAGEMENT_BULK_STATUS, body);
  }
  updateDetail(id: string, body: any): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity(
      'PATCH',
      USER_MANAGEMENT_STATUS_UPDATE.replace('{id}', id),
      body,
    );
  }
  getById(id: string): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity('GET', USER_BY_ID_BASE_URL.replace('{id}', id));
  }
  getProfessionalById(id: string): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity('GET', PROFESSIONAL_BY_ID_BASE_URL.replace('{id}', id));
  }
  getHomeOwnerById(id: string): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity('GET', HOME_OWNER_BY_ID_BASE_URL.replace('{id}', id));
  }
  updateProfile(id: string, formData: any): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity(
      'PATCH',
      UPDATE_USER_PICTURE_BASE_URL.replace('{id}', id),
      formData,
      this.httpService.multiPartFormDataHeaders,
    );
  }
  updateAddress(id: string, formData: any): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity(
      'PATCH',
      UPDATE_USER_ADDRESS_BASE_URL.replace('{id}', id),
      formData,
      this.httpService.multiPartFormDataHeaders,
    );
  }

  delete(id: string): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity('DELETE', USER_BY_ID_BASE_URL.replace('{id}', id));
  }
  importData(formData: FormData): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity(
      'POST',
      USER_MANAGEMENT_IMPORT_EXCEL,
      formData,
      this.httpService.multiPartFormDataHeaders,
      true,
    );
  }
  download(link: string): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity(
      'POST',
      link,
      {}, // Set an empty object as the third argument
      this.httpService.formDataHeaders, // Headers remain the same
      true,
      false,
      false,
      true,
    );
  }
  downloadGet(
    link: string = USER_MANAGEMENT_DOWNLOAD_TEMPLATE,
  ): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity(
      'GET',
      link,
      {}, // Set an empty object as the third argument
      this.httpService.formDataHeaders, // Headers remain the same
      true,
      false,
      false,
      true,
    );
  }
  downloadDocuments(id: string): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity(
      'GET',
      DOWNLOAD_USER_DOCUMETS_BASE_URL.replace('{documentFileId}', id),
    );
  }
  deleteDocument(data: any): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity(
      'DELETE',
      DELETE_USER_DOCUMETS_BASE_URL.replace('{documentId}', data),
    );
  }
}
