import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ExpandedImageviewService } from './expanded-imageview.service';

@Component({
  selector: 'expanded-imageview',
  templateUrl: './expanded-imageview.component.html',
  styleUrls: ['./expanded-imageview.component.scss'],
  imports: [CommonModule],
  standalone: true,
})
export class ExpandedImageviewComponent implements OnInit {
  constructor(private expandedImageviewService: ExpandedImageviewService) {}

  get open() {
    return this.expandedImageviewService.isOpen;
  }

  get config() {
    return this.expandedImageviewService.config;
  }

  discardWrapper(event: Event) {
    this.expandedImageviewService.isOpen = false;
    if (this.config.discard) {
      this.config.discard(event);
    }
  }

  saveWrapper(event: Event) {
    this.expandedImageviewService.isOpen = false;
    if (this.config.save) {
      this.config.save(event);
    }
  }

  ngOnInit() {}

}

export class ExpandedImageviewConfig {
  imageUrl?: string = '';
  save?: Function = () => {};
  discard?: Function = () => {};

  constructor(
    imageUrl: string = '',
    save = null,
    discard = null
  ) {
    if (imageUrl) this.imageUrl = imageUrl;
    if (save) this.save = save;
    if (discard) this.discard = discard;
  }
}