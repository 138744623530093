import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { COMMON_MODULES } from '@src/app/core/constants/common-module.constant';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-saz';
import { BaseComponent } from '@src/app/core/base';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs';
import { DatePipe } from '@angular/common';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DebounceClickDirectiveModule } from '@src/app/core/shared/directives/debounceClick/debounceClick.directive.module';
import { DEFAULT_DEBOUNCE_TIME } from '@src/app/core/constants';
import { ViewPermissionDirectiveModule } from '@src/app/core/shared/directives/view-permission/view-permission.directive.module';
import { NoConsecutiveSpacesDirectiveModule } from '@src/app/core/shared/directives/noConsecutiveSpaces/noConsecutiveSpaces.directive.module';
import { Debounce } from '@src/app/core/decorator/debounce.decorator';

import * as constants from '@src/app/core/constants/system.constant';

import { ApiResponseInterface } from '@src/app/core/interfaces';
import { UserManagementService } from '@src/app/features/user-management/core';
import { SubCategoriesRepository } from '../../core/repositories/sub-categories.repository';
import { Category } from '@src/app/core/api';
import { SubCategoryUpdateRequest } from '../../core/classes/sub-category-update-request';
import { SubCategoryCreateRequest } from '../../core/classes/sub-category-create-request';
import { OnlyIntegerDirectiveModule } from '@src/app/core/shared/directives';

export enum Role {
  Admin = 'admin',
  'Content Creator' = 'content_creator',
  Operator = 'operator',
}

@Component({
  selector: 'app-sub-category-info',
  templateUrl: './sub-category-info.component.html',
  styleUrls: ['./sub-category-info.component.scss'],
  preserveWhitespaces: true,
  standalone: true,
  imports: [
    ...COMMON_MODULES,
    NgSelectModule,
    NgxIntlTelInputModule,
    NgbDropdownModule,
    NgbTooltipModule,
    DebounceClickDirectiveModule,
    ViewPermissionDirectiveModule,
    NoConsecutiveSpacesDirectiveModule,
    OnlyIntegerDirectiveModule
  ],
  providers: [DatePipe],
})
export class SubCategoryInfoComponent extends BaseComponent implements OnInit, OnDestroy {

  public categoryList: Category[] = [];

  public formGroup: FormGroup = new FormGroup({
    name: new FormControl(null, [
      Validators.required,
      Validators.pattern(constants.subCategoryTitleRegex),  // Pattern check (alphabet and spaces)
      Validators.maxLength(60),  // Max length check (60 characters)
    ]),
    description: new FormControl('', [
      Validators.pattern(constants.subCategoryDescriptionRegex), // Pattern check (alphabet and spaces)
      Validators.maxLength(100),  // Max length check (100 characters)
    ]),  
    id: new FormControl(null), // Autogenerated and greyed out
    order: new FormControl(null, [Validators.required, Validators.min(1), Validators.max(99)]), // Mandatory dropdown
    parentCategoryId: new FormControl(null, [Validators.required]), // Mandatory dropdown
    isActivated: new FormControl(null)
  });
  public subcategoryOrderOptions: number[] = [];


  formBackup = this.helperService.clone(this.formGroup.value);
  public hasSpecificRole: boolean = false;
  public userType: any = 'admin_user';
  public disableRoles: boolean = false;

  /**
   * Constructor of the SubCategoryInfoComponent
   * @param injector The injector.
   * @param datePipe The DatePipe.
   */
  constructor(
    injector: Injector,
    private userManagementService: UserManagementService,
    private subCategoriesRepository: SubCategoriesRepository
  ) {
    super(injector);
    // If the page is not in edit mode, set the pageMode to 'add'
    if (this.isEditMode() == null) {
      this.pageMode = 'add';
    }
  }
  /**
   * Listens for changes in the form group and updates the form backup and data modified flag.
   *
   * @return {void} This function does not return anything.
   */
  @Debounce(constants.DEFAULT_DEBOUNCE_TIME_1_SEC)
  onChanges(): void {
    this.formGroup.valueChanges.pipe(this.destroy$()).subscribe(val => {

       
      if (this.formBackup !== 'reset') {
        let formData: any = this.helperService.clone(val);

        formData.phoneNumber =
          formData.phoneNumber && formData.phoneNumber.number
            ? formData.phoneNumber.number
            : formData.phoneNumber;
        formData.landlineNumber =
          formData.landlineNumber && formData.landlineNumber.number
            ? formData.landlineNumber.number
            : formData.landlineNumber;
        this.userManagementService.isDataModified = !this.helperService.isEqual(
          this.formBackup,
          formData,
        );
        this.sharedDataService.dataModified.next({
          component: 'sub-category-info',
          isModified: this.userManagementService.isDataModified,
          data: this.helperService.clone(val),
        });
      }
    });
  }

  /**
   * @description
   * Initializes component and retrieves sub category data from route resolver
   */
  ngOnInit(): void {
    this.getCategories()
    this.subcategoryOrderOptions = Array.from({ length: 99 }, (_, i) => i + 1); // Values from 1 to 99

    this.activatedRoute.queryParams.subscribe((params: any) => {

      if (params['type']) {
        this.userType = params['type'];
        if (this.userType == 'app_user' && this.pageMode == 'edit') {
          this.disableRoles = true;
        } else this.disableRoles = false;
      }
    });
    /**
     * Retrieves user data from route resolver
     * Stores user data in component property `baseModel`
     * Also stores a copy of user's roles in `baseModel.rolesData`
     * Checks if user is a super admin
     *   and sets `pageMode` property to 'view' if true
     * Converts user's role IDs to an array of strings
     * Removes '+' prefix from phone numbers if present
     * Formats phone numbers to E.164 format
     * Removes 'null' and '+null' from landlineNumber
     * Formats date of birth to ISO format
     * Calls `setProfile`
     */
    this.dataSubscription$ = this.activatedRoute.data
      .pipe(takeUntil(this._unsubscribeToastrMessage$))
      .subscribe((data: any) => {

        if (data?.subCategory) {
          this.baseModel = this.helperService.clone(data?.subCategory);
          this.setSubCategory();
        }
        this.baseModel.isActivated = data?.subCategory.isActivated;

      });

    // this.onChanges();
  }

  /**
   * Finds all invalid controls in the given form
   * @param form The form to search for invalid controls
   * @returns The names of the invalid controls
   */
  public findInvalidControls(form: any): string[] {
    const invalid = []; // The names of the invalid controls
    const controls = form.controls; // The controls of the given form

    // Loop through all the controls in the form
    for (const name in controls) {
      // If the control is invalid
      if (controls[name].invalid) {
        // Add its name to the list of invalid controls
        invalid.push(name);
      }
    }
    return invalid;
  }

  /**
   * Submits the user profile based on the specified type.
   *
   * @param {string} type - The type of submission ('draft' or 'create').
   * @return {void} This function does not return a value.
   */

  protected submitSubCategory(): void {
    const { valid, value } = this.formGroup;
    if (valid) {
      // Determine API endpoint and HTTP method based on whether it's an update or creation
      let apiContainer = null;
      if (this.baseModel?.id) {
        apiContainer = this.subCategoriesRepository.update(this.baseModel.id, this.parseRequestModel(value));
      }
      else {
        apiContainer = this.subCategoriesRepository.create(this.parseRequestModel(value));
      }

      apiContainer
        .pipe(this.destroy$(), distinctUntilChanged(), debounceTime(DEFAULT_DEBOUNCE_TIME))
        .subscribe({
          /**
           * Executes the next callback function asynchronously. If the response status is SUCCESS_STATUS or SUCCESS_STATUS_CODE,
           * it updates the calendar and shows error messages if any. Otherwise, it shows success message.
           *
           * @param {any} res - The response object.
           * @return {Promise<void>} A promise that resolves when the function completes.
           */
          next: async (res: any) => {
            this.httpService.showMessage(
              this.baseModel?.id
                ? this.translate.instant('MSGS.SUB_CATEGORIES.SUB_CATEGORY_UPDATED')
                : this.translate.instant('MSGS.SUB_CATEGORIES.SUB_CATEGORY_CREATED'),
              'success',
            );
            this.router.navigate(['/subcategories']);
          },
        });
    }
  }

  private parseRequestModel(baseModel: any): any {

     
    if (this.baseModel?.id) {
      return new SubCategoryUpdateRequest(
        baseModel.name,
        baseModel.description,
        baseModel.order,
        baseModel.isActivated,
        baseModel.parentCategoryId
      );
    }
    else{
      return new SubCategoryCreateRequest(
        baseModel.name,
        baseModel.description,
        baseModel.order,
        baseModel.parentCategoryId
      );
    }
  }

  /**
   * Sets the form values to the base model
   *
   * This function is called in the ngOnInit lifecycle hook,
   * after getting the user profile from the API.
   *
   * It patches the formGroup with the baseModel values,
   * and updates the formGroup validity to emit events.
   */
  setSubCategory(id?: string) {
    this.formGroup.updateValueAndValidity({ emitEvent: false });
    if (id === 'cancel') {
      this.formGroup.patchValue({}, { emitEvent: false });
      this.helperService.goToRoute(this.routeList.APP_SUB_CATEGORY);
    } else {
      this.formGroup.patchValue({
        name: this.baseModel.name,                    // Set subCategoryId
        description: this.baseModel.description,
        id: this.baseModel.id,
        order: this.baseModel.order,
        parentCategoryId: this.baseModel.parentCategory?.id,
        isActivated: this.baseModel.isActivated
      });

      // this.formGroup.patchValue({ ...this.baseModel }, { emitEvent: false });
    }
  }

  getCategories() {
    this.subCategoriesRepository
      .getCategories()
      .pipe(this.destroy$(), distinctUntilChanged(), debounceTime(DEFAULT_DEBOUNCE_TIME))
      .subscribe({
        next: (response: unknown) => {
          const res = response as ApiResponseInterface<any>;
          if (res?.body) {
            this.categoryList = res.body.data.filter((category: any) => category.isActivated);
          }
        },
      });
  }

}
