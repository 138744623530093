import { Injectable } from '@angular/core';
import { ExpandedImageviewConfig } from './expanded-imageview.component';

@Injectable({
  providedIn: 'root',
})
export class ExpandedImageviewService {
  isOpen = false;
  config = new ExpandedImageviewConfig();

  constructor() {}

  open(config: ExpandedImageviewConfig) {
    this.config = { ...this.config, ...config };
    this.isOpen = true;
  }
}