import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { COMMON_MODULES } from '@src/app/core/constants/common-module.constant';
import { NgbDropdownModule, NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '@src/app/core/base';
import { takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from '@src/app/core/shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogModule } from '@src/app/core/shared/components/confirm-dialog/confirm-dialog.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { StoreRepository } from '../../core/repositories/store.repository';
import { ApiResponseInterface } from '@src/app/core/interfaces';
import { STORE_MANAGEMENT_STATUS_UPDATE, StoreManagementService } from '@src/app/features/store-management/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DebounceClickDirectiveModule } from '@src/app/core/shared/directives/debounceClick/debounceClick.directive.module';
import { DEFAULT_DEBOUNCE_TIME, S3_BUCKET_URL } from '@src/app/core/constants';
import { ViewPermissionDirectiveModule } from '@src/app/core/shared/directives/view-permission/view-permission.directive.module';
import { NoConsecutiveSpacesDirectiveModule } from '@src/app/core/shared/directives/noConsecutiveSpaces/noConsecutiveSpaces.directive.module';
import { Debounce } from '@src/app/core/decorator/debounce.decorator';
import { SharedModule } from "../../../../core/shared/shared.module";
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-saz';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input-saz';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { AppStatus } from '@src/app/core/shared/components/app-status/app-status.component';
import { PageSettingsService } from '@src/app/core/services/pageSetting.service';


export enum Role {
  Admin = 'admin',
  'Content Creator' = 'content_creator',
  Operator = 'operator',
}
@Component({
  selector: 'store-detail',
  templateUrl: './store-detail.component.html',
  styleUrls: ['./store-detail.component.scss'],
  preserveWhitespaces: true,
  standalone: true,
  imports: [
    ...COMMON_MODULES,
    NgbNavModule,
    NgbNavModule,
    NgSelectModule,
    ConfirmDialogModule,
    NgbDropdownModule,
    NgbTooltipModule,
    DebounceClickDirectiveModule,
    ViewPermissionDirectiveModule,
    NoConsecutiveSpacesDirectiveModule,
    SharedModule,
    AppStatus,
    NgxIntlTelInputModule
  ],
})
export class StoreDetailComponent extends BaseComponent implements OnInit {

  @ViewChild(ConfirmDialogComponent, { static: false }) confirmDialCompRef!: ConfirmDialogComponent;
  public dialogMessage: any = '';
  public dialogConfirmText: any = '';
  public confirmEventAction: string = '';
  public disableRoles: boolean = false;
  public isLoading: boolean = false;
  // public active: number = 1;
  public userType: any = 'admin_user';
  public hasSpecificRole: boolean = false;
  public CountryISO = CountryISO;
  public storeLogo: any = 'assets/images/thumbnail.svg';
  public image: any = null;
  public phoneNumberFormat = PhoneNumberFormat;
  public SearchCountryField = SearchCountryField;
  public rolesList: any = Role;
  public S3_BUCKET_URL = S3_BUCKET_URL

  public formGroup: FormGroup = new FormGroup({
    id: new FormControl(null),
    name: new FormControl(null, Validators.compose([Validators.required,  Validators.maxLength(60)])),
    email: new FormControl(null, [
      Validators.email,
      Validators.required,
      Validators.pattern(this.constantList.emailRegex),
    ]),
    phoneNumber: new FormControl(null, [
      Validators.required,
    ]),
    address: new FormControl(null, [
      Validators.required,
    ]),
    imagePath: new FormControl(null),
  });
  formBackup = this.helperService.clone(this.formGroup.value);

  public column: any = {
    key: 'status',
    value: 'COMPONENTS.STORES.FIELDS.STATUS',
    headingClass: 'ps-4',
    type: 'status',
    sortable: false,
    defaultTitle: true,
    options: [
      {
        label: 'GENERAL.BUTTONS.ACTIVATE',
        value: 'active',
        icon: 'tick-circle',
      },
      {
        label: 'GENERAL.BUTTONS.DEACTIVATE',
        value: 'inactive',
        icon: 'close-circle',
      },
    ],
    endpoint: STORE_MANAGEMENT_STATUS_UPDATE,
    success: this.translate.instant('MSGS.STORE.UPDATE_STORE_STATUS'),
    successSecond: this.translate.instant('MSGS.STORE.UPDATE_STORE_STATUS_DEACTIVATED'),
    alert: this.translate.instant('MSGS.STORE.ARE_YOU_SURE_DEACTIVATE'),
    alertSecond: this.translate.instant('MSGS.STORE.ARE_YOU_SURE_ACTIVATE'),
    error: this.translate.instant('MSGS.STORE.UNABLE_TO_UPDATE_STORE_STATUS'),
  };

  /**
   * Component constructor
   * @param injector injects required services
   * @param pageSettingsService service for managing page settings in BaseComponent
   * @param {PageSettingsService} pageSettingsService - Service for managing page settings
   */
  constructor(
    injector: Injector,
    private storeRepository: StoreRepository,
    private storeManagementService: StoreManagementService,
    private pageSettingsService: PageSettingsService, // Service for managing page settings
  ) {
    super(injector);
    // If the page is not in edit mode, set the pageMode to 'add'
    if (this.isEditMode() == null) {
      this.pageMode = 'add';
    }
    // Get the store from the resolver
    this.getStore();
    
    const newSettings = {
      link1: {
        title: 'BREADCRUMBS.PRODUCTS_MANAGEMENT.TITLE', // Title for the page
        name: 'MENU.STORES', // Name for the breadcrumb
        link: this.routeList.STORES, // Link for the breadcrumb
      },
      link2: {
        title:
          this.pageMode === 'add'
            ? 'BREADCRUMBS.STORE_MANAGEMENT.ADD_STORE'
            : 'BREADCRUMBS.STORE_MANAGEMENT.EDIT_STORE',
      },
    };

    // Use the service to update the pageSettings in BaseComponent
    this.pageSettingsService.updatePageSettings(newSettings);
  }

  /**
   * Initializes the component and performs any necessary setup operations.
   *
   * This function is called when the component is first created and is used to
   * retrieve any necessary data from the URL parameters, such as the email and
   * token, and perform any initial setup tasks.
   *
   * @return {void} This function does not return a value.
   */
  /**
    * @description
    * Initializes component and retrieves user data from route resolver
    */
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params['type']) {
        this.userType = params['type'];
        if (this.userType == 'app_user' && this.pageMode == 'edit') {
          this.disableRoles = true;
        } else this.disableRoles = false;
      }
    });
    /**
     * Retrieves user data from route resolver
     * Stores user data in component property `baseModel`
     * Also stores a copy of user's roles in `baseModel.rolesData`
     * Checks if user is a super admin
     *   and sets `pageMode` property to 'view' if true
     * Converts user's role IDs to an array of strings
     * Removes '+' prefix from phone numbers if present
     * Formats phone numbers to E.164 format
     * Removes 'null' and '+null' from landlineNumber
     * Formats date of birth to ISO format
     * Calls `setProfile`
     */
    this.dataSubscription$ = this.activatedRoute.data
      .pipe(takeUntil(this._unsubscribeToastrMessage$))
      .subscribe((data: any) => {
        if (data?.store) {
          this.baseModel = this.helperService.clone(data?.store);

          this.setStore();
        }
      });

    // this.onChanges();
  }

  /**
   * Subscribes to the data observable from the ActivatedRoute and
   * sets the baseModel property with the data from the observable.
   */
  getStore() {
    this.dataSubscription$ = this.activatedRoute.data
      .pipe(takeUntil(this._unsubscribeToastrMessage$))
      .subscribe((data: any) => {
        if (data?.store) {
          /**
           * The store data from the ActivatedRoute data observable.
           */
          this.baseModel = data?.store;

        }
      });
  }


  /**
   * Reset the password form fields
   *
   * @param form The form to reset
   */
  resetPasswordForm(form: any) {
    // eslint-disable-line @typescript-eslint/explicit-module-boundary-types
    form.reset(); // Reset the form fields
  }

  /**
   * Get role details by ID
   *
   * @param id The ID of the role
   * @returns The role details if found, empty object otherwise
   */
  getRoleDetailsbyId(id: any): any {
    const index = this.rolesList.findIndex((s: any) => s.id == id);
    if (index !== -1) {
      return this.rolesList[index];
    } else {
      return {}; // Empty object if not found
    }
  }

  /**
   * Finds all invalid controls in the given form
   * @param form The form to search for invalid controls
   * @returns The names of the invalid controls
   */
  public findInvalidControls(form: any): string[] {
    const invalid = []; // The names of the invalid controls
    const controls = form.controls; // The controls of the given form

    // Loop through all the controls in the form
    for (const name in controls) {
      // If the control is invalid
      if (controls[name].invalid) {
        // Add its name to the list of invalid controls
        invalid.push(name);
      }
    }
    return invalid;
  }

  /**
 * Updates the status of the segment.
 *
 * @param {string} status - The new status of the segment.
 * @return {void} This function does not return anything.
 */
  public updateStatus(status: string) {

    this.httpService
      .requestEntity(
        'PATCH',
        STORE_MANAGEMENT_STATUS_UPDATE.replace('{id}', this.baseModel?.id),
        {
          status: status,
        },
      )
      .pipe(this.destroy$())
      .subscribe({
        /**
         * Handles the next callback of the subscription.
         *
         * @param {any} res - The response object.
         * @return {void} This function does not return anything.
         */
        next: (res: any) => {
          if (res.status === this.constantList.SUCCESS_STATUS || res.status === this.constantList.SUCCESS_STATUS_CODE) {
            this.httpService.showMessage(
              this.translate.instant('MSGS.GENERAL.STATUS_UPDATED_SUCCESS'),
              'success',
            );
            this.baseModel.isActivated = res.body?.isActivated;

          }
        },
      });
  }

  /**
   * Submits store based on the specified type.
   *
   * @param {string} type - The type of submission ('draft' or 'create').
   * @return {void} This function does not return a value.
   */
  protected onSubmit(type: string): void {
    let { valid, value } = this.formGroup;
    let formData = new FormData();
    if (type === 'add') {
      if (!this.image?.file) {
        this.httpService.showMessage(this.translate.instant('MSGS.GENERAL.INVALID_IMAGE'), 'error');
        return;
      }
      value.imagePath = this.image?.file;
      formData.append('logo', this.image.file);
    }
    else if (type === 'update' && this.image?.file) {
      formData.append('logo', this.image.file);
    }

    formData.append('name', value.name);
    formData.append('email', value.email);
    formData.append('address', value.address);
    formData.append('phoneNumber', value.phoneNumber.e164Number);

    if (valid) {
      // Determine API endpoint and HTTP method based on whether it's an update or creation
      let apiContainer = this.storeRepository.create(formData);
      if (this.baseModel?.id) {
        apiContainer = this.storeRepository.update(this.baseModel.id, formData);
      }

      apiContainer
        .pipe(this.destroy$(), distinctUntilChanged(), debounceTime(DEFAULT_DEBOUNCE_TIME))
        .subscribe({
          /**
           * Executes the next callback function asynchronously. If the response status is SUCCESS_STATUS or SUCCESS_STATUS_CODE,
           * it updates the calendar and shows error messages if any. Otherwise, it shows success message.
           *
           * @param {any} res - The response object.
           * @return {Promise<void>} A promise that resolves when the function completes.
           */
          next: async (res: any) => {
            this.httpService.showMessage(
              `${type === 'update'
                ? this.translate.instant('MSGS.STORE.UPDATE_STORE_SUCCESS_MESSAGE')
                : this.translate.instant('MSGS.STORE.CREATE_STORE_SUCCESS_MESSAGE')
              }`,
              'success',
            );
            this.router.navigate([this.routeList.STORES]);
          },
        });
    }
  }

  /**
   * Sets the form values to the base model
   *
   * This function is called in the ngOnInit lifecycle hook,
   * after getting the user profile from the API.
   *
   * It patches the formGroup with the baseModel values,
   * and updates the formGroup validity to emit events.
   */
  setStore(id?: string) {
    this.formGroup.updateValueAndValidity({ emitEvent: false });
    if (id === 'cancel') {
      this.formGroup.patchValue({}, { emitEvent: false });
      this.helperService.goToRoute(this.routeList.STORES);
    } else {
      this.formGroup.patchValue({ ...this.baseModel }, { emitEvent: false });
      this.storeLogo = this.baseModel.imagePath;
    }
  }

  /**
   * Handles file input change event
   * @param event file input change event
   */
  onPictureUpload(event: any) {
    // if (!event.target.files[0]?.type.includes('image') || !(event.target.files[0].size < 300000) ) {
    //   this.httpService.showMessage(this.translate.instant('MSGS.GENERAL.INVALID_IMAGE'), 'error');
    //   return;
    // }
    // Set the selected image details to the image variable
    this.image = {
      file: event.target.files[0],
      fileName: event.target.files[0]?.name,
    };
    // Create an instance of FileReader
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const img = new Image();
      img.src = e.target.result;  // Use FileReader result as the image source

      // Directly access image dimensions when available
      img.onload = () => {
        // if (img.width != 1200 || img.height != 900) {
        //   this.httpService.showMessage(this.translate.instant('MSGS.GENERAL.INVALID_IMAGE_DIMENSION'), 'error');
        //   return;
        // } else {
          // Set the image source to the result of reading the image
          this.storeLogo = reader.result;
        // }
      };
    };
    
    // Read the image as a data url
    reader.readAsDataURL(new Blob([event.target.files[0]], { type: event.target.files[0].type }));
  }

}

